import * as React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useToken, useProperties, PropertyContext } from './api';
import * as screens from './screens';

interface PageProps {
    component: () => JSX.Element,
}

function PrivatePage({ component: Component }: PageProps): JSX.Element {
    const { token } = useToken();
    if (!token) {
        return <Redirect to="/" />
    }
    return <Component />;
}

function PublicPage({ component: Component }: PageProps): JSX.Element {
    const { token } = useToken();
    if (token) {
        return <Redirect to="/properties" />
    }
    return <Component />;
}

function PropertiesRoute(): JSX.Element {
    const { state, error, data } = useProperties();
    if (state === 'error') {
        return (
            <screens.Base>Error {error}</screens.Base>
        );
    }
    if (state === 'initial' || state === 'loading' || !data) {
        return (
            <screens.Base loading={true} />
        );
    }
    const { properties } = data;
    return (
        <PropertyContext.Provider value={{ properties }}>
            <Switch>
                <Route exact path="/properties" component={screens.PropertyList} />
                <Route exact path="/properties/:id" component={screens.PropertyDetails} />
                <Route exact path="/properties/:id/bfs-export" component={screens.BfsExport} />
                <Route exact path="/properties/:id/sidap-export" component={screens.SidapExport} />
                <Route exact path="/properties/:id/tessin-export" component={screens.TessinExport} />
                <Route exact path="/properties/:id/nw-export" component={screens.NwExport} />
                <Route exact path="/properties/:id/tourx-export" component={screens.TourxExport} />
                <Route exact path="/properties/:id/bexio-accounting" component={screens.BexioAccounting} />
                <Route exact path="/properties/:id/motaubh-export" component={screens.MotauBH} />
                <Route component={screens.NotFound} />
            </Switch>
        </PropertyContext.Provider>
    );
}

function App() {
    return (
        <screens.UserInfoProvider>
            <screens.SubscriptionProvider>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <PublicPage component={screens.Login} />
                        </Route>
                        <Route exact path="/oauth/bexio/callback">
                            <PrivatePage component={screens.BexioOAuth} />
                        </Route>
                        <Route path="/properties">
                            <PrivatePage component={PropertiesRoute} />
                        </Route>
                        <Route exact path="/history">
                            <PrivatePage component={screens.History} />
                        </Route>
                        <Route component={screens.NotFound} />
                    </Switch>
                </Router>
            </screens.SubscriptionProvider>
        </screens.UserInfoProvider>
    );
}

export default App;
